@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

.ant-multi-select {
    @apply text-blue-pacific;
    box-sizing: border-box;
    height: 34px;
    font-size: 15px;

    .ant-select-selector {
        @apply shadow-md;
        @apply border-none;
        display: block;
        border: 0;
        border-radius: 0;
        padding: 0 4px;
    }

    .ant-select-selection-search {
        margin: 0;
        display: inline-block;
    }

    .ant-select-selection-item {
        display: inline-flex;
    }

    .ant-select-selection-placeholder {
        @apply text-blue-pacific;
        left: 5px;
    }
}

.dropdown-icon {
    &:after {
        @extend %fa-icon;
        @extend .fas;
        text-decoration: inherit;
        padding-left: 10px;
        font-size: 1.5rem;
        line-height: 16px;
        text-align: right;
        right: 8px;
        top: -3%;
        pointer-events: none;
        position: absolute;
        height: 0px;
        overflow: visible;
        content: fa-content($fa-var-caret-down);
        color: black;
    }
    &.dropdown-icon--disabled {
        &:after {
            color: gray;
        }
    }
}